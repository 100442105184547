<template>
  <div class="flex flex-wrap items-end justify-between xl:flex-nowrap">
    <div
      class="mb-2 flex w-full flex-wrap items-center md:inline-flex md:w-auto md:space-x-4 xl:mb-0"
    >
      <h1
        class="mb-1 w-full font-display text-base font-black tracking-wide md:w-auto md:text-xl lg:mb-0"
      >
        {{ dateTitle }}
      </h1>
      <WaitingTimeDisplay v-if="country" :country="country" />
    </div>
    <div
      id="filterSection"
      class="flex flex-col justify-between space-y-2 md:flex-row md:space-x-4 md:space-y-0"
    >
      <div
        v-if="!hasSelectedDigitalClinic"
        class="inline-flex w-full items-center md:w-auto"
      >
        <div class="flex w-full space-x-2">
          <div class="w-56">
            <custom-select
              size="sm"
              label-size="sm"
              :value="serviceId"
              label-attribute="name"
              value-attribute="id"
              :options="services"
              @change="setService($event), $emit('submit')"
            />
          </div>
          <div class="w-64">
            <custom-select
              placeholder="All veterinarians"
              size="sm"
              label-size="sm"
              :value="vetId"
              label-attr="display_name"
              value-attr="id"
              :options="vets"
              :searchable="true"
              :loading="!vets.length"
              @change="setVet($event), $emit('submit')"
            />
          </div>
        </div>
      </div>

      <div class="flex space-x-2 md:inline-flex">
        <div class="inline-flex space-x-2">
          <base-button color="cancel" size="xs" class="h-10 w-32 md:w-40">
            <div class="inline-flex items-center justify-between pr-2">
              <datepicker
                :value="correctFromDate"
                input-class="w-full font-semibold bg-transparent text-sm cursor-pointer focus:outline-none py-0"
                wrapper-class=""
                calendar-class="font-normal rounded shadow uppercase text-sm left-auto"
                :monday-first="true"
                maximum-view="day"
                :use-utc="true"
                :format="customFormatter"
                @input="setViewDate($event)"
              />
              <fv-icon icon="chevron-down" class="text-xs text-gray-600" />
            </div>
          </base-button>
          <base-button color="cancel" size="sm" @click="$emit('select-today')">
            <span class="text-sm text-gray-700">Today</span>
          </base-button>
          <base-button @click="$emit('refresh-events')">
            <fv-icon class="text-sm text-gray-700" icon="switch" />
          </base-button>
        </div>

        <div class="inline-flex space-x-1">
          <base-button @click="$emit('prev-date')">
            <fv-icon class="text-xs text-gray-700" icon="chevron-left" />
          </base-button>
          <base-button @click="$emit('next-date')">
            <fv-icon class="text-xs text-gray-700" icon="chevron-right" />
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import WaitingTimeDisplay from '@/components/admin/schedule/schedule-displays/WaitingTimeDisplay';
import { format, parseISO } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

export default {
  components: {
    Datepicker,
    WaitingTimeDisplay,
  },
  computed: {
    ...mapState('admin/schedule', [
      'serviceId',
      'services',
      'vets',
      'vetId',
      'fromDate',
    ]),
    ...mapGetters('admin/digital-clinic', {
      hasSelectedDigitalClinic: 'getDigitalClinicId',
    }),
    ...mapGetters('admin', ['country']),

    correctFromDate() {
      if (this.fromDate) {
        return formatInTimeZone(
          new Date(this.fromDate),
          'Europe/London',
          'yyyy-MM-dd'
        );
      }
      return null;
    },

    dateTitle() {
      if (this.fromDate) {
        return format(parseISO(this.fromDate), 'EEEE, MMMM do yyyy');
      }
      return null;
    },

    datePickerDate() {
      if (this.fromDate) {
        return format(
          zonedTimeToUtc(new Date(this.fromDate), 'Europe/London'),
          'yyyy-MM-dd'
        );
      }
      return null;
    },
  },
  methods: {
    ...mapMutations('admin/schedule', {
      setService: 'setService',
      setVet: 'setVet',
    }),
    customFormatter(date) {
      return format(
        new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000),
        'yyyy-MM-dd'
      );
    },
    setViewDate(date) {
      this.$emit('selected-date', this.customFormatter(date));
    },
  },
};
</script>

<style lang="postcss">
.vdp-datepicker__calendar .cell.today:not(.selected) {
  @apply rounded border-dashed border-primary-dark;
}

.vdp-datepicker__calendar .cell.selected {
  @apply text-white;
  @apply font-black;
}
</style>
