var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-wrap items-end justify-between xl:flex-nowrap" },
    [
      _c(
        "div",
        {
          staticClass:
            "mb-2 flex w-full flex-wrap items-center md:inline-flex md:w-auto md:space-x-4 xl:mb-0",
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "mb-1 w-full font-display text-base font-black tracking-wide md:w-auto md:text-xl lg:mb-0",
            },
            [_vm._v(" " + _vm._s(_vm.dateTitle) + " ")]
          ),
          _vm.country
            ? _c("WaitingTimeDisplay", { attrs: { country: _vm.country } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col justify-between space-y-2 md:flex-row md:space-x-4 md:space-y-0",
          attrs: { id: "filterSection" },
        },
        [
          !_vm.hasSelectedDigitalClinic
            ? _c(
                "div",
                { staticClass: "inline-flex w-full items-center md:w-auto" },
                [
                  _c("div", { staticClass: "flex w-full space-x-2" }, [
                    _c(
                      "div",
                      { staticClass: "w-56" },
                      [
                        _c("custom-select", {
                          attrs: {
                            size: "sm",
                            "label-size": "sm",
                            value: _vm.serviceId,
                            "label-attribute": "name",
                            "value-attribute": "id",
                            options: _vm.services,
                          },
                          on: {
                            change: function ($event) {
                              _vm.setService($event), _vm.$emit("submit")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-64" },
                      [
                        _c("custom-select", {
                          attrs: {
                            placeholder: "All veterinarians",
                            size: "sm",
                            "label-size": "sm",
                            value: _vm.vetId,
                            "label-attr": "display_name",
                            "value-attr": "id",
                            options: _vm.vets,
                            searchable: true,
                            loading: !_vm.vets.length,
                          },
                          on: {
                            change: function ($event) {
                              _vm.setVet($event), _vm.$emit("submit")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "flex space-x-2 md:inline-flex" }, [
            _c(
              "div",
              { staticClass: "inline-flex space-x-2" },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "h-10 w-32 md:w-40",
                    attrs: { color: "cancel", size: "xs" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "inline-flex items-center justify-between pr-2",
                      },
                      [
                        _c("datepicker", {
                          attrs: {
                            value: _vm.correctFromDate,
                            "input-class":
                              "w-full font-semibold bg-transparent text-sm cursor-pointer focus:outline-none py-0",
                            "wrapper-class": "",
                            "calendar-class":
                              "font-normal rounded shadow uppercase text-sm left-auto",
                            "monday-first": true,
                            "maximum-view": "day",
                            "use-utc": true,
                            format: _vm.customFormatter,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setViewDate($event)
                            },
                          },
                        }),
                        _c("fv-icon", {
                          staticClass: "text-xs text-gray-600",
                          attrs: { icon: "chevron-down" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "base-button",
                  {
                    attrs: { color: "cancel", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("select-today")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text-sm text-gray-700" }, [
                      _vm._v("Today"),
                    ]),
                  ]
                ),
                _c(
                  "base-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("refresh-events")
                      },
                    },
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "text-sm text-gray-700",
                      attrs: { icon: "switch" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inline-flex space-x-1" },
              [
                _c(
                  "base-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("prev-date")
                      },
                    },
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "text-xs text-gray-700",
                      attrs: { icon: "chevron-left" },
                    }),
                  ],
                  1
                ),
                _c(
                  "base-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$emit("next-date")
                      },
                    },
                  },
                  [
                    _c("fv-icon", {
                      staticClass: "text-xs text-gray-700",
                      attrs: { icon: "chevron-right" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }